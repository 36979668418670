import { useAuthContext } from 'components/ui-core/auth/AuthContext';
import { useASTData } from 'context/ASTContext';
import { useSCVersioning } from 'data/queryHooks';
import SideBarOption from 'model/SideBarOption';
import React, { useState, createContext, useContext, useEffect } from 'react';

const BranchContext = createContext();
export const useBranchData = () => useContext(BranchContext);

const BranchContextProvider = ({ children }) => {
    const [currentSpaceCraft, setCurrentSpaceCraft] = useState();
    const [currentDatabase, setCurrentDatabase] = useState();
	const [dbList, setDbList] = useState([]);
    const [totalDbList, setTotalDbList] = useState([]);

    const [metadataDb, setMetadataDb] = useState([]);

    const [sc_options, setSpacecraftOptions] = useState([{label: "BW3", value: "BW3"}])
    const [db_options, setDbOptions] = useState([{label: "FLIGHT_DEV", value: "FLIGHT_DEV"}])



    React.useEffect(() => {
        totalDbList.map((row) => {
            row.editable = false
            if (/^FLIGHT_DEV_MSD_(\d{1,2}|[0-9]{1,2})_BACKUP$/.test(row.version)) {
                row.enableMerge = false
                row.editable = false
            }
            if (/^FLIGHT_DEV_MSD_(\d{1,2}|[0-9]{1,2})_MERGE$/.test(row.version)) {
                row.enableMerge = true;
            }
            if (/^FLIGHT_DEV_MSD_(\d{1,2}|[0-9]{1,2})$/.test(row.version)) {
                row.enableMerge = (totalDbList.filter(ele => ele.version.includes(row.version + '_MERGE')).length == 0) && (totalDbList.filter(ele => ele.version.includes(row.version + '_BACKUP')).length > 0);
            }
            
            return row
        });
    },[totalDbList]);

	const value = {
		// Db Context
		dbList, 
		setDbList,

		totalDbList, 
		setTotalDbList,

        sc_options,
        setSpacecraftOptions,

        db_options,
        setDbOptions,

        currentSpaceCraft,
        setCurrentSpaceCraft,

        currentDatabase,
        setCurrentDatabase,

        metadataDb,
        setMetadataDb
	
	};

	return <BranchContext.Provider value={value}>{children}</BranchContext.Provider>;
};

export default BranchContextProvider;
